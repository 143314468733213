import { ObjectType } from '@/@types/graphql-types';
import type { ToastButton } from '@ionic/core/dist/types/components/toast/toast-interface';
import type { TranslateResult } from 'vue-i18n';

import { getVm } from '@/helpers/vm-helper';
import { TitleListName } from '@/interfaces/responses/title-list';
import { ListMutationTitleDetailParam } from '../providers/title-actions-provider';

let tCtr: any = null;

export interface ToastCallback {
	message: string;
	callback: Function;
}

export enum ToastIconSide {
	START = 'start',
	END = 'end',
}

export type ToastColors =
	| 'primary'
	| 'secondary'
	| 'tertiary'
	| 'success'
	| 'warning'
	| 'danger'
	| 'light'
	| 'medium'
	| 'dark';

export type ToastPosition = 'bottom' | 'middle' | 'top';
export type ToastAction = 'add' | 'remove';

const TOAST_DEFAULT_MESSAGE_TIMEOUT = 1500;
const LIST_TRANSLATABLES = () => ({
	add: {
		[TitleListName.WATCHLIST]: getVm().$t('WEBAPP_WATCHLIST_TOAST_ADDED'),
		[TitleListName.SEENLIST]: getVm().$t('WEBAPP_SEENLIST_TOAST_SEEN'),
		[TitleListName.LIKELIST]: getVm().$t('WEBAPP_LIKELIST_TOAST_ADDED'),
		[TitleListName.DISLIKELIST]: getVm().$t('WEBAPP_DISLIKELIST_TOAST_ADDED'),
	},
	remove: {
		[TitleListName.WATCHLIST]: getVm().$t('WEBAPP_WATCHLIST_TOAST_REMOVED'),
		[TitleListName.SEENLIST]: getVm().$t('WEBAPP_SEENLIST_TOAST_UNSEEN'),
		[TitleListName.LIKELIST]: getVm().$t('WEBAPP_LIKELIST_TOAST_REMOVED'),
		[TitleListName.DISLIKELIST]: getVm().$t('WEBAPP_DISLIKELIST_TOAST_REMOVED'),
	},
});

const init = async () => {
	const { addIcons, checkmarkCircleOutline, arrowUndo } = await import('@/ionic.loader');

	// manually need to add ion-icon
	addIcons({
		undo: arrowUndo,
		'checkmark-circle-outline': checkmarkCircleOutline,
	});

	if (!tCtr) {
		const { toastController } = await import('@/ionic.loader');
		tCtr = toastController;
	}
};

const addToast = async ({
	message,
	duration = TOAST_DEFAULT_MESSAGE_TIMEOUT,
	buttons = [],
	position = 'bottom',
	color = 'tertiary',
	cssClass = 'jw-toast',
	showCloseButton = true,
}: {
	message: string;
	duration?: number;
	buttons?: ToastButton[];
	position?: ToastPosition;
	color?: ToastColors;
	cssClass?: string;
	showCloseButton?: boolean;
}) => {
	if (showCloseButton) {
		const closeButtonText = getVm().$t('WEBAPP_CLOSE') as string;
		const closeButton: ToastButton = {
			text: closeButtonText,
			role: 'cancel',
			side: ToastIconSide.END,
		};
		buttons = [...buttons, closeButton];
	}

	await init();

	const toast = await tCtr.create({
		message,
		duration,
		position,
		color,
		cssClass,
		buttons,
	});

	return await toast.present();
};
const toastSuccess = ({
	message,
	action,
	duration = TOAST_DEFAULT_MESSAGE_TIMEOUT,
	position = 'bottom',
	closeButton = true,
}: {
	message: string;
	action?: ToastButton;
	duration?: number;
	position?: ToastPosition;
	closeButton?: boolean;
}) => {
	const icon: ToastButton = {
		icon: 'checkmark-circle-outline',
		side: ToastIconSide.START,
	};

	const buttons: ToastButton[] = [icon];
	if (action) {
		buttons.push(action);
		closeButton = false;
	}
	addToast({ message, duration, buttons, position, showCloseButton: closeButton });
};

const toastError = ({
	message,
	action,
	duration = TOAST_DEFAULT_MESSAGE_TIMEOUT,
	position = 'bottom',
	closeButton = true,
}: {
	message: string;
	action?: ToastButton;
	duration?: number;
	position?: ToastPosition;
	closeButton?: boolean;
}) => {
	const icon: ToastButton = {
		icon: 'close',
		side: ToastIconSide.START,
	};

	const buttons: ToastButton[] = [icon];
	if (action) {
		buttons.push(action);
		closeButton = false;
	}

	addToast({ message, duration, buttons, position, color: 'danger', showCloseButton: closeButton });
};

const showSuccessWithUndo = ({
	message,
	undoCallback,
	duration,
}: {
	message: string;
	undoCallback: ToastCallback;
	duration?: number;
}) => {
	const { message: undoMessage, callback } = undoCallback;
	// Undo Action callback wrapper
	const undoActionCallback = (message: TranslateResult) => async () => {
		const icon: ToastButton = {
			icon: 'undo',
			side: ToastIconSide.START,
		};

		callback();

		addToast({ message: String(message), duration, buttons: [icon] });
	};

	// Undo Action for Toast
	const trUndoButtonText = getVm().$t('WEBAPP_UNDO_BUTTON') as string;
	const action: ToastButton = createToastAction(trUndoButtonText, undoActionCallback(undoMessage));
	toastSuccess({ message, action, duration, closeButton: false });
};

const createToastAction = (text: string, callback: Function): ToastButton => {
	return {
		text,
		handler: () => {
			try {
				callback();
			} catch (err) {
				// error message
				console.error('[createToastAction] callback err: ', err);
			}
		},
	};
};

let translatable: Record<ToastAction, any> | null = null;
const getListTranslatable = (listAction: ToastAction, listType: TitleListName) => {
	if (!translatable) {
		translatable = LIST_TRANSLATABLES();
	}
	return (translatable[listAction] as any)[listType] as string;
};

function getSeenToastMessage(
	isTitleInSeenlist: boolean,
	payload: {
		titleObjectType: ObjectType;
		titleName: string;
		showSeason?: number;
		showEpisode?: number;
	}
) {
	const trUnseenMessage = getVm().$t('WEBAPP_SEENLIST_TOAST_UNSEEN');
	const trSeenMessage = getVm().$t('WEBAPP_SEENLIST_TOAST_SEEN');
	const { titleName, showEpisode, showSeason } = payload;
	let messageTitle = titleName;

	if (showSeason) {
		messageTitle += ` S${showSeason}`;
	}

	if (showEpisode) {
		messageTitle += ` E${showSeason}`;
	}

	return !isTitleInSeenlist ? `${messageTitle} ${trSeenMessage}` : `${messageTitle} ${trUnseenMessage}`;
}

function addTitleToListToast(
	titleListName: TitleListName,
	titleDetails: ListMutationTitleDetailParam,
	undoCallback?: Function
) {
	// exclude more lists for now

	if (titleListName !== TitleListName.MORELIST) {
		const trAddMessage = getListTranslatable('add', titleListName);
		const trRemoveMessage = getListTranslatable('remove', titleListName);
		const fullTitle = getFullTitle(titleListName, titleDetails);

		const undoAction = {
			message: `${fullTitle} ${trRemoveMessage}`,
			callback: () => undoCallback && undoCallback(),
		};
		showSuccessWithUndo({ message: `${fullTitle} ${trAddMessage}`, undoCallback: undoAction });
	}
}

function removeTitleFromListToast(titleListName: TitleListName, titleDetails: ListMutationTitleDetailParam) {
	const fullTitle = getFullTitle(titleListName, titleDetails);
	// exclude more lists for now
	if (titleListName !== TitleListName.MORELIST) {
		const trRemoveMessage = getListTranslatable('remove', titleListName);
		toastSuccess({ message: `${fullTitle} ${trRemoveMessage}` });
	}
}

function getFullTitle(titleListName: TitleListName, titleDetails: ListMutationTitleDetailParam) {
	if (titleListName === TitleListName.SEENLIST)
		return titleDetails.objectType === ObjectType.ShowEpisode &&
			titleDetails.seasonNumber &&
			titleDetails.episodeNumber
			? `${titleDetails.title} - ${getVm().$t('WEBAPP_SEASON_X_EPISODE_Y', {
					season: titleDetails.seasonNumber,
					episode: titleDetails.episodeNumber,
			  })}`
			: titleDetails.title;

	return [TitleListName.LIKELIST, TitleListName.DISLIKELIST].includes(titleListName) && !!titleDetails.seasonTitle
		? `${titleDetails.title} - ${titleDetails.seasonTitle}`
		: titleDetails.title;
}

export {
	addTitleToListToast,
	addToast,
	createToastAction,
	getListTranslatable,
	getSeenToastMessage,
	init,
	removeTitleFromListToast,
	showSuccessWithUndo,
	toastError,
	toastSuccess,
};
